import React, { useState, useEffect, useRef } from 'react';
import './Chat.scss';
import { Message, MessageType } from '../../models/ChatInterfaces';
import TextArea from "../form/TextArea/TextArea";
import FormButton from '../form/FormButton/FormButton';
import PageRow from '../common/PageRow/PageRow';
import Spinner from 'react-bootstrap/Spinner'; 

interface ChatProps {
    messages: Message[];
    onMessageSubmit: (messageText: string) => void;
    maxHeight?: string; // Optional maxHeight prop to control the scrollable area
    isLoading: boolean;
}

const Chat: React.FC<ChatProps> = ({ messages, onMessageSubmit, maxHeight, isLoading }) => {
    const [inputText, setInputText] = useState('');
    const messagesEndRef = useRef<null | HTMLDivElement>(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        //only scroll when more than one message (avoid scrolling for the first default message):
        if (messages.length > 1){
            scrollToBottom();
        }
    }, [messages]); // Dependency on messages means it will auto-scroll every time messages update

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInputText(event.target.value);
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            if (!isLoading) { // Ensure button is not disabled due to loading
                handleQuestionSubmit();
            }
        }
    };

    const handleQuestionSubmit = () => {
        if (inputText.trim() !== '') {
            onMessageSubmit(inputText);
            setInputText('');
        }
    };

    const chatContentStyle: React.CSSProperties = {
        maxHeight,
        overflowY: maxHeight ? 'auto' : 'visible'
    };

    return (
        <div className="chat-container">
            <PageRow className="pb-2 px-2">
                <div className="chat-content col-md-12 mb-2" style={chatContentStyle}>
                    {messages.map((msg, index) => (
                        <div key={index} className={msg.type === MessageType.Question ? "chat-bubble-right" : "chat-bubble-left"}>
                            {msg.text}
                        </div>
                    ))}
                    <div ref={messagesEndRef} /> {/* Invisible div for scrolling into view */}
                </div>
                {isLoading && <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>}
            </PageRow>
            <PageRow>
                <TextArea
                    id="chat-question-area"
                    label="Question:"
                    name="Question"
                    value={inputText}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyPress}
                    placeholder="Type your message here"
                    rows={5}
                    scrollable={true}
                    columnClass="col-md-12 px-2"
                    showLabel={false}
                />
            </PageRow>
            <PageRow>
                <FormButton
                    label="Ask Ava"
                    onClick={handleQuestionSubmit}
                    columnClass="col-md-4 px-2"
                    disabled={inputText.trim() === '' || isLoading} // Disable button if no input or still loading
                />
            </PageRow>
        </div>
    );
};

export default Chat;