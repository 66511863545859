import { ApiClient } from './apiClient';
import { WorkflowResponse,PromptResponse,MandatoryPromptResponse } from '../models/ContractInterfaces';
import { v4 as uuidv4 } from 'uuid';
import {ContractClass} from '../models/ContractClass';
import{JobClass} from  '../models/JobClass';

interface FetchedResponses {
  data: {
      history: Array<WorkflowResponse>;
  }[];
}

   
export class ApiWorkflow {
    private apiClient: ApiClient;
    constructor(apiClient: ApiClient) {
        this.apiClient = apiClient;
    }

    public async generateWorkflowResponses_tobedeleted(jobId: string, workflowId: string, userId: string,job: JobClass, contract:ContractClass): Promise<WorkflowResponse[]>{
        const subUrl = 'genai/generateResponses';
        const payload = {
            processingType: "workflow",
            userId: userId,
            jobId: jobId,
            conversationId: uuidv4(),
            contractMetadata: {
                contractId: contract.contractId,
                createdAt: contract.createdAt,
            },
            workflowId: workflowId
        };

        try {
          const response = await this.apiClient.post<FetchedResponses>(subUrl, JSON.stringify(payload));
          
          return response.data[0].history
        } catch (error) {
            throw new Error(`API call failed: ${error}`);
        }
    }

    public async generateWorkflowResponses(
        jobId: string, 
        workflowId: string, 
        userId: string, 
        job: JobClass, 
        contract: ContractClass
    ): Promise<WorkflowResponse[]> {
        const subUrl = 'genai/generateResponses';
        const conversationId = uuidv4();
        const payload = {
            processingType: "workflow",
            userId: userId,
            jobId: jobId,
            conversationId: conversationId,
            contractMetadata: {
                contractId: contract.contractId,
                createdAt: contract.createdAt,
            },
            workflowId: workflowId
        };
    
        // Fire off the request to generate responses without awaiting it
        this.apiClient.post<FetchedResponses>(subUrl, JSON.stringify(payload));
            
        // Proceed directly to wait for the results
        return await this.waitForWorkflowResults(conversationId);
    }

    public async fetchWorkflowResults(sessionId: string): Promise<WorkflowResponse[]> {
        const subUrl = 'genai/fetchData?dataType=conversationHistory';
        const payload = {
            conversationId: sessionId
        };
    
        try {
            const response = await this.apiClient.post<FetchedResponses>(subUrl, JSON.stringify(payload));
            return response.data[0].history
        } catch (error) {
            throw new Error(`API call failed: ${error}`);
        }
    }

    private async waitForWorkflowResults(sessionId: string): Promise<WorkflowResponse[]> {
        const maxRetries = 15;
        let attempt = 0;

        while (attempt < maxRetries) {
            try {
                // Wait 5 seconds before each attempt
                await new Promise(resolve => setTimeout(resolve, 5000));

                // Try to fetch the workflow results
                const results = await this.fetchWorkflowResults(sessionId);
                return results;
            } catch (error) {
                attempt++;
                
                if (attempt === maxRetries) {
                    console.error(`Attempt ${attempt} failed: ${error}`);
                    throw new Error(`Failed to fetch results after ${maxRetries} attempts: ${error}`);
                }
            }
        }
        throw new Error('Unreachable code');
    }
}
