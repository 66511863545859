import React , { useState } from 'react';
import PageHeader from "../../components/common/PageHeader/PageHeader";
import PageRow from "../../components/common/PageRow/PageRow";
import Card from "../../components/common/Card/Card";
import FormButton from  '../../components/form/FormButton/FormButton';
import TextArea from "../../components/form/TextArea/TextArea";
import { useApiClient } from '../../contexts/ApiContext';
import { useAuth } from '../../contexts/AuthContext';

const Token: React.FC = () => {
    const { token } = useAuth();
    const apiClient = useApiClient();
    const [newToken,updateToken] = useState('');

    const handleTokenChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = e.target.value;
        updateToken(newValue);
    }

    return (
    <>
        <PageHeader title="Token" />
        <PageRow className='px-4'>
        <Card title="Update Token" columnSize="col-md-12">
            <PageRow>
            <TextArea
                id="token"
                label="Set new Token:"
                name="token"
                value={token??''}
                onChange={handleTokenChange}
                placeholder="Paste Token from Sandbox here"
                rows={5}
                scrollable={true}
                columnClass='col-md-12 px-2'
            />
            </PageRow>
            <PageRow>
                <FormButton
                    columnClass='col-md-4 px-2' 
                    label="Update Token"
                    onClick={() => {
                        apiClient.updateToken(newToken);
                        updateToken('');
                    }
                } />
            </PageRow>
            </Card>
        </PageRow>
    </>
    );
}

export default Token;
                    
                                