import React, { useId } from 'react';
import './Card.scss';

interface CardProps {
  title: string;
  columnSize?: string; // Optional prop for column size, e.g., "col-md-5"
  children?: React.ReactNode; // Using children for body content
  cardBodyClass?: string; // Optional class for card body
  hideCardBody?: boolean; // Optional prop to hide the card body
  onToggle?: () => void; // Function to toggle visibility of card body
  onMoveUp?: () => void;
  onMoveDown?: () => void;
  onDeleteCard?: () => void;
}

const Card: React.FC<CardProps> = ({
  title,
  children,
  columnSize = "col-md-12",
  hideCardBody = false,
  cardBodyClass = "",
  onMoveUp,
  onMoveDown,
  onDeleteCard,
  onToggle
}) => {
  const uniqueId = useId();

  const handleHeaderClick = (e: React.MouseEvent) => {
    // Prevent toggle if a button inside the header was clicked
    if (onToggle && !(e.target as HTMLElement).closest('button')) {
      onToggle();
    }
  };

  return (
    <div className={`${columnSize} m-0 py-2`}>
      <div className="card h-100">
        <div
          className="card-header"
          onClick={handleHeaderClick}
          style={{ cursor: onToggle ? 'pointer' : 'default' }}
        >
          <span>{title}</span>
          <div className="card-header-buttons">
            {onMoveUp && (
              <button
                onClick={onMoveUp}
                className="btn btn-outline-secondary card-header-button"
                title="Move Up"
              >
                <i className="bi bi-arrow-up"></i>
              </button>
            )}
            {onMoveDown && (
              <button
                onClick={onMoveDown}
                className="btn btn-outline-secondary card-header-button"
                title="Move Down"
              >
                <i className="bi bi-arrow-down"></i>
              </button>
            )}
            {onDeleteCard && (
              <button
                onClick={onDeleteCard}
                className="btn btn-outline-secondary card-header-button"
                title="Delete"
              >
                <i className="bi bi-trash"></i>
              </button>
            )}
            {onToggle && (
              <button
                onClick={(e) => {
                  e.stopPropagation(); // Prevent the header click event
                  onToggle();
                }}
                className="btn btn-outline-secondary card-header-button"
              >
                {hideCardBody ? (
                  <i className="bi bi-eye"></i> // Indicates that the body is hidden
                ) : (
                  <i className="bi bi-eye-slash"></i> // Indicates that the body is visible
                )}
              </button>
            )}
          </div>
        </div>
        {!hideCardBody && (
          <div className={`${cardBodyClass} card-body`}>{children}</div>
        )}
      </div>
    </div>
  );
};

export default Card;