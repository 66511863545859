export enum UserRole {
    Commercial = 'Commercial',
    Operation = 'Operation',
    Technology = 'Technology',
    Administration = 'Administration',
    Other = 'Other'
  }
  
  export interface Permissions {
    [key: string]: {
      xmlType: string[];
      readAccess: boolean;
      writeAccess: boolean;
    };
  }
  
  export interface User {
    name: string;
    email: string;
    phone?: string;
    role?: UserRole;
    companyName?: string;
    permissions: Permissions;
  }