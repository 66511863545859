import React , { useState,useEffect } from 'react';
import PageHeader from "../../components/common/PageHeader/PageHeader";
import PageRow from "../../components/common/PageRow/PageRow";
import Card from "../../components/common/Card/Card";
import SelectDropdown from '../../components/form/SelectOption/SelectDropdown';
import FormButton from  '../../components/form/FormButton/FormButton';
import TextInput from '../../components/form/TextInput/TextInput';
import TextArea from '../../components/form/TextArea/TextArea';
import FileDropArea  from '../../components/form/FileDropArea/FileDropArea';

import {ContractClass} from '../../models/ContractClass';
import {useApiClient } from '../../contexts/ApiContext';
import {ApiContract, ContractListData} from '../../api/apiContract';
import {createPdfBufferFromText} from '../../utils/pdfUtil';
import {ApiJob} from '../../api/apiJob';
import {usePage} from '../../contexts/PageContext';
import { useUser } from '../../contexts/UserContext';


const DefaultContent: React.FC = () => {
    const { navigate } = usePage();
    // Dynamic Data
    const [contractOptions, setContractOptions] = useState([{ value: 'Select Contract', label: 'Select Contract' }]);
    const [workflowOptions,setWorkflowOptions] = useState([{ value: 'Select Workflow', label: 'Select Workflow' }]);
    const [contractDefinitionList, setContractDefinitionList] = useState<ContractListData[]>([]);

    // API
    const apiClient = useApiClient();
    const [apiContract, setApiContract] = useState<ApiContract | null>(null);

    // States
    const [selectedContractId, setSelectedContractId] = useState('');
    const [selectedContract, setSelectedContract] = useState<ContractClass | null>(null);
    const [selectedWorkflowId, setselectedWorkflowId] = useState('');
    
    // Form
    const [jobName, setJobName] = useState('');
    const [jobComments, setJobComments] = useState('');
    const [contractRecap, setContractRecap] = useState('');
    const [files, setFiles] = useState<File[]>([]);  // State to store files
    const [submitted, setSubmitted] = useState(false); // State to track if the form has been submitted
    const defaultFileNamePastedText = 'Pasted_text.pdf';
    
    // User:
    const { getUserId } = useUser();

    // Visuals
    const [isCardBodyHidden, setIsCardBodyHidden] = useState(false);

    // Effects:
    useEffect(() => {
        if (apiClient) {
            const newApiContract = new ApiContract(apiClient);
            setApiContract(newApiContract);
        }
    }, [apiClient]);
    // Fetch contracts when apiContract is initialized or changed
    useEffect(() => {
        if (apiContract) {
            const newContractOptions = [
                { value: 'Select Contract', label: 'Select Contract' },
            ];
            const fetchContracts = async () => {
                try {
                    const contractList = await apiContract.fetchContractListFromApi();
                    setContractDefinitionList(contractList);
                    // Create options for the dropdown
                    contractList.forEach((contract: { contractId: string; contractName: string; }) => {
                        newContractOptions.push({ value: contract.contractId, label: contract.contractName });
                    });
                    setContractOptions(newContractOptions);
                } catch (error) {
                    throw error;
                }
            };
            fetchContracts();
        }
    }, [apiContract]);

    // -------Form Handlers-------
    const handleSelectContract = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        const contractId = e.target.value; // Extracting the value from the event object
        const contractVersion = contractDefinitionList.find((contract) => contract.contractId === contractId)?.createdAt ?? "2023-01-01";
        setSelectedContractId(contractId); // Assuming this is the value from the dropdown
    
        if (contractId !== 'Select Contract' && apiContract) {
            try {
                const contractData = await apiContract.fetchContractMetaFromApi(contractId, contractVersion);
    
                // Ensure contractData is an instance of ContractClass
                let contract: ContractClass;
                if (contractData instanceof ContractClass) {
                    contract = contractData;
                } else {
                    contract = new ContractClass(contractData);
                }
    
                // Get name, stored in contract list
                contract.contractName = contractDefinitionList.find((contract) => contract.contractId === contractId)?.contractName ?? "";
                setSelectedContract(contract);
    
                // Update list of workflows
                const newWorkflowOptions = [
                    { value: 'Select Workflow', label: 'Select Workflow' }
                ];
                if (contract.contractDetails.workflows.length > 0) {
                    contract.contractDetails.workflows.forEach((workflow) => {
                        newWorkflowOptions.push({ value: workflow.workflowId, label: workflow.workflowName });
                    });
                }
                setWorkflowOptions(newWorkflowOptions);
                setselectedWorkflowId(''); // Reset selected workflow id
            } catch (error) {
                setSelectedContract(null); // Reset or handle errors appropriately
                throw error;
            }
        } else {
            setSelectedContract(null); // Reset when "Select Contract" is chosen
        }
    };
    //handle workflow change
    const handleWorkflowChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = e.target.value;
        setselectedWorkflowId(newValue);
    };
    // create a submit handler
    const handleSubmit = async () => {
        // check selectedContract first, if not, quick return
        if (!selectedContract) {
            return;
        }
        try {
            setSubmitted(true);
            let updatedFiles;
            // Create the PDF buffer from the recap text
            if (contractRecap.trim() !== '') {
                const pdfBuffer = await createPdfBufferFromText(contractRecap);
                const pdfBlob = new Blob([pdfBuffer], { type: 'application/pdf' });
                const pdfFile = new File([pdfBlob], defaultFileNamePastedText, { type: 'application/pdf' });
                
                // Find the dummy file in the files array and replace it with the new PDF file in a copy of the array
                const dummyFileIndex = files.findIndex((file) => file.name === defaultFileNamePastedText);
                updatedFiles = files.map((file, index) => (index === dummyFileIndex ? pdfFile : file));
                setFiles(updatedFiles);

            } else {
                // Update the files array to exclude the dummy file
                const dummyFileIndex = files.findIndex((file) => file.name === defaultFileNamePastedText);
                if (dummyFileIndex > -1) {
                    updatedFiles = files.filter((_, index) => index !== dummyFileIndex);
                } else {
                    updatedFiles = files;
                }  
                setFiles(updatedFiles);
            }
            
            // Create a new Job:
            const apiJob = new ApiJob(apiClient);
            // Upload the files to the server
            const contractVersion = selectedContract.createdAt;
            await apiJob.uploadFilesAndCreateJob(updatedFiles,getUserId() ?? 'unknown',selectedContract,contractVersion,selectedWorkflowId,jobName,jobComments);
            navigate('workflows', {'contract':selectedContractId, 'job': apiJob.jobId, 'workflow': selectedWorkflowId, 'user': getUserId() ?? 'unknown','loading': 'true'});

        } catch (error) {
            setSubmitted(false);
            throw error;
        }
    };

    // -------Form Handlers-------
    //handle contract name change
    const handleJobNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setJobName(newValue);
    };
    // handle comments change
    const handleCommentsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = e.target.value;
        setJobComments(newValue);
    };
    // handle recap change
    const handleRecapChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = e.target.value;
        setContractRecap(newValue);
    };
    // handle file drop
    const handleFileDrop = (files: File[]) => {
        setFiles(files);
    };
    
    // -------Visual Handlers-------
    const toggleCardBody = () => {
        setIsCardBodyHidden(!isCardBodyHidden);
    };

    return (
        <>
        <PageHeader title="Upload a New Contract" />
        <PageRow className='px-4'>
            <Card 
                columnSize="col-md-8"
                title="Contract Details"
                >
                <PageRow>
                    <div className="col-md-12">
                        <PageRow>
                            <SelectDropdown
                                id="contract-select"
                                label="Contract Type:*"
                                options={contractOptions}
                                value={selectedContractId}
                                onChange={handleSelectContract}
                                columnClass="col-md-4 m-0 px-2 pl-md-2 pe-md-0"
                            />
                            <TextInput
                                id="contract-name"
                                label="Contract Name:*"
                                name="Name" 
                                value={jobName}
                                onChange={handleJobNameChange}
                                columnClass='col-md-8 px-2'
                            />
                        </PageRow>
                        <PageRow>
                            <SelectDropdown 
                                id="workflow-type"
                                label="Initial Workflow:*"
                                options={workflowOptions}
                                value={selectedWorkflowId??"Select Workflow"}
                                onChange={handleWorkflowChange}
                                columnClass="col-md-4 px-2 pl-md-2 pe-md-0"
                            />
                        </PageRow>        
                        <PageRow>
                            <TextArea
                                id="contract-comments"
                                label="Comments:"
                                name="Comments" 
                                value={jobComments}
                                onChange={handleCommentsChange}
                                columnClass='col-md-12 px-2'
                                rows={3}
                                scrollable={true}
                            />
                        </PageRow>
                        <PageRow>
                            <TextArea 
                                id="recap"
                                label={`Text from e-mail or chat (${defaultFileNamePastedText}):`}
                                name="recap" 
                                value={contractRecap}
                                onChange={handleRecapChange}
                                columnClass='col-md-12 px-2'
                                rows={15}
                                scrollable={true}
                            />
                        </PageRow>
                        <PageRow>
                            <FileDropArea onFileDrop={handleFileDrop} pastedTextFileName={defaultFileNamePastedText} />
                        </PageRow>
                        <PageRow className='pt-4'>
                            <div className="col-md-8 px-2"></div>
                            <FormButton
                                label="Analyze Contract"
                                onClick={handleSubmit}
                                columnClass="col-md-4 px-2"
                                disabled={
                                    jobName.trim() === '' ||
                                    selectedContractId === 'Select Contract' ||
                                    selectedContractId === '' ||
                                    selectedWorkflowId === 'Select Workflow' ||
                                    selectedWorkflowId === '' ||
                                    submitted ||
                                    (files.length === 1 && contractRecap.trim() === '')
                                }
                            />
                        </PageRow>
                    </div>
                </PageRow>
            </Card>
        </PageRow>
        </>
        );
    };

    export default DefaultContent;