import React, { useState } from 'react';
import {Workflow, PromptGroup,MandatoryPrompt,MandatoryPromptResponse,PromptResponse,WorkflowResponse } from '../../models/ContractInterfaces';
import './WorkflowPrompts.scss';

interface WorkflowPromptsProps {
    workflowResponses: WorkflowResponse[];
    workflow: Workflow | null;
}

const WorkflowPrompts: React.FC<WorkflowPromptsProps> = ({ workflowResponses, workflow }) => {
    // State to manage visibility of citations for each prompt
    const [visibleCitations, setVisibleCitations] = useState<{ [key: string]: boolean }>({});
    
    // State to manage visibility of extra prompts (4+)
    const [showAllPrompts, setShowAllPrompts] = useState<{ [key: string]: boolean }>({});

    // Function to generate unique key for each prompt
    const getPromptKey = (prompt: PromptResponse): string => `${prompt.promptGroupId}-${prompt.promptId}`;

    // Function to toggle the visibility of citations for a given prompt
    const toggleCitations = (promptKey: string) => {
        setVisibleCitations(prevState => ({
            ...prevState,
            [promptKey]: !prevState[promptKey]
        }));
    };

    // Function to toggle visibility of 4+ prompts
    const toggleShowAllPrompts = (groupId: string) => {
        setShowAllPrompts(prevState => ({
            ...prevState,
            [groupId]: !prevState[groupId]
        }));
    };

    // Type guard to check if a WorkflowResponse is a PromptResponse
    function isPromptResponse(response: WorkflowResponse): response is PromptResponse {
        return (response as PromptResponse).promptId !== undefined;
    }

    // Type guard to check if a WorkflowResponse is a MandatoryPromptResponse
    function isMandatoryPromptResponse(response: WorkflowResponse): response is MandatoryPromptResponse {
        return (response as MandatoryPromptResponse).mandatoryPromptId !== undefined;
    }

    // Separate the responses into two lists
    const promptResponseList = workflowResponses.filter(isPromptResponse);
    const mandatoryResponseList = workflowResponses.filter(isMandatoryPromptResponse);
    const mandatoryWithAdvice = mandatoryResponseList.filter(
        (mandatory) => mandatory.promptAdvice && mandatory.promptAdvice.trim() !== ''
    );

    // Function to group and sort prompts by PromptGroupId and then by PromptId
    const groupPrompts = (): Map<string, PromptResponse[]> => {
        const grouped = new Map<string, PromptResponse[]>();

        // Group the prompts by promptGroupId
        promptResponseList.forEach(prompt => {
            const group = grouped.get(prompt.promptGroupId) || [];
            group.push(prompt);
            grouped.set(prompt.promptGroupId, group);
        });

        // Sort the prompt groups by promptGroupId (optional, since Map keys aren’t naturally ordered)
        const sortedGrouped = new Map(
            Array.from(grouped.entries()).sort((a, b) => a[0].localeCompare(b[0]))
        );

        // For each promptGroup, sort the prompts by promptId (converted to integer)
        sortedGrouped.forEach((prompts, groupId) => {
            sortedGrouped.set(
                groupId,
                prompts.sort((a, b) => parseInt(a.promptId) - parseInt(b.promptId))
            );
        });

        return sortedGrouped;
    };

    const groupMandatoryPrompts = (): Map<string, MandatoryPromptResponse[]> => {
        const grouped = new Map<string, MandatoryPromptResponse[]>();
        mandatoryWithAdvice.forEach((mandatory) => {
            const group = grouped.get(mandatory.promptGroupId) || [];
            group.push(mandatory);
            grouped.set(mandatory.promptGroupId, group);
        });
        return grouped;
    };

    const groupAllPrompts = (): Map<string, Array<PromptResponse | MandatoryPromptResponse>> => {
        const promptGroups = groupPrompts();
        const mandatoryGroups = groupMandatoryPrompts();
        const combinedGroups = new Map<string, Array<PromptResponse | MandatoryPromptResponse>>();
    
        // Use either mandatory prompts or regular prompts, but not both
        const allGroupIds = new Set([...Array.from(promptGroups.keys()), ...Array.from(mandatoryGroups.keys())]);
    
        Array.from(allGroupIds)
            .sort((a, b) => a.localeCompare(b)) // Sort group IDs alphabetically
            .forEach((groupId) => {
                const promptsOrMandatoryPrompts =
                    mandatoryGroups.get(groupId) || promptGroups.get(groupId) || [];
    
                // Sort the items within the group by key (promptId or mandatoryPromptId)
                const sortedItems = promptsOrMandatoryPrompts.sort((a, b) => {
                    const aKey = (a as PromptResponse).promptId || (a as MandatoryPromptResponse).mandatoryPromptId;
                    const bKey = (b as PromptResponse).promptId || (b as MandatoryPromptResponse).mandatoryPromptId;
                    return parseInt(aKey, 10) - parseInt(bKey, 10);
                });
    
                combinedGroups.set(groupId, sortedItems);
            });
        return combinedGroups;
    };

    // Retrieve the prompt group details
    const getPromptGroupDetails = (promptGroupId: string): PromptGroup | undefined => {
        return workflow?.promptGroups.find(group => group.promptGroupId === promptGroupId);
    };

    // Group and sort the prompt Groups:
    const groupedPrompts = groupAllPrompts();

    if (groupedPrompts.size === 0) {
        return <p>No findings available (you may have to select workflow).</p>;
    }

    const renderCitations = (promptKey: string, parsedAnswer: any): JSX.Element | null => {
        if (visibleCitations[promptKey] && parsedAnswer?.citations?.length > 0) {
            return (
                <div className="mt-2">
                    <strong>Citations:</strong>
                    <ul className="list-unstyled">
                        {parsedAnswer.citations.map((citation: string, index: number) => (
                            <li key={index}>{citation}</li>
                        ))}
                    </ul>
                </div>
            );
        }
        return null;
    };
    
    const formatPromptAnswerContent = (prompt: PromptResponse): JSX.Element => {
        const promptKey = getPromptKey(prompt);
        let parsedAnswer: any;
        if (typeof prompt.answer === 'string') {
            try {
                parsedAnswer = JSON.parse(prompt.answer || '{}');
            } catch {
                parsedAnswer = null; // Parsing failed
                console.error('Error parsing answer:', prompt.answer);
            }
        } else if (typeof prompt.answer === 'object') {
            parsedAnswer = prompt.answer;
        } else {
            parsedAnswer = null;
        }
    
        const hasCitations = parsedAnswer && Array.isArray(parsedAnswer.citations) && parsedAnswer.citations.length > 0;
        const hasAdvice = prompt.promptAdvice && prompt.promptAdvice.trim() !== '';

        const checkSingleLineResult = (promptType: string): boolean => {
            return promptType !== 'BOOLEAN' && promptType !== 'TEXT MULTILINE' && promptType !== 'COMPARE';
        };
        const getClassNamePromptAnswer = (promptType: string): string => {
            return checkSingleLineResult(promptType)
                ? 'promptSingleLineAnswer p-1'
                : 'promptAnswer p-4';
        };
        const renderAdvice = () => {
            if (!hasAdvice) return null;
    
            const adviceClass = prompt.promptImportance === 'HIGH' ? 'high-importance' : 'normal-importance';
            return (
                <p>
                    <strong className={adviceClass}>
                        Advice ({prompt.promptImportance} {prompt.promptImportance === 'HIGH' ? 'Risk' : ''}):
                    </strong> {prompt.promptAdvice}
                </p>
            );
        };
    
        const renderRefSuperscript = () => {
            if (prompt.enableCitation && hasCitations) {
                return (
                    <sup>
                        <a
                            href="#"
                            className="toggle-link"
                            onClick={(e) => { e.preventDefault(); toggleCitations(promptKey); }}
                        >
                            (citations)
                        </a>
                    </sup>
                );
            }
            return null;
        };
    
        return (
            <div className={`mb-1 ${getClassNamePromptAnswer(prompt.promptType)}`} key={getPromptKey(prompt)}>
                {prompt.promptType === 'BOOLEAN' && (
                    <>
                        <h6>
                            {prompt.promptName}
                        </h6>
                        {renderAdvice()}
                        {renderRefSuperscript()}
                        {renderCitations(promptKey, parsedAnswer)}
                    </>
                )}
                {prompt.promptType === 'TEXT MULTILINE' && (
                    <>
                        <h6>
                            {prompt.promptName}
                        </h6>
                        
                        {parsedAnswer && <p>{parsedAnswer.answer}</p>}
                        {renderAdvice()}
                        {renderRefSuperscript()}
                        {renderCitations(promptKey, parsedAnswer)}
                    </>
                )}
                {prompt.promptType === 'COMPARE' && (
                    <>
                        <h6>
                            {prompt.promptName}
                        </h6>
                        {parsedAnswer && <p>{parsedAnswer.answer}</p>}
                        {renderAdvice()}
                        {renderRefSuperscript()}
                        {renderCitations(promptKey, parsedAnswer)}
                    </>
                )}
                {checkSingleLineResult(prompt.promptType) && (
                    <>
                        <strong>{prompt.promptName}: </strong>
                        {parsedAnswer && <span>{parsedAnswer.answer}</span>}
                        {!parsedAnswer && typeof prompt.answer === 'string' && <span>{prompt.answer}</span>}
                        <br />
                        {renderAdvice()}
                        {renderRefSuperscript()}
                        {renderCitations(promptKey, parsedAnswer)}
                    </>
                )}
            </div>
        );
    };

    const getMandatoryPromptImportance = (
            promptGroupId: string,
            mandatoryPromptId: string
        ): string => {
            if (!workflow) {
                console.error("Workflow is not defined.");
                return "NEUTRAL"; // Default importance
            }
        
            // Find the relevant PromptGroup
            const promptGroup = workflow.promptGroups.find((group) => group.promptGroupId === promptGroupId);
        
            if (!promptGroup) {
                console.error(`PromptGroup with ID ${promptGroupId} not found.`);
                return "NEUTRAL"; // Default importance
            }
        
            // Find the relevant MandatoryPrompt
            const mandatoryPrompt = promptGroup.mandatoryPrompts.find(
                (prompt) => prompt.mandatoryPromptId === mandatoryPromptId
            );
        
            if (!mandatoryPrompt) {
                console.error(
                    `MandatoryPrompt with ID ${mandatoryPromptId} not found in PromptGroup ${promptGroupId}.`
                );
                return "NEUTRAL"; // Default importance
            }
        
            // Return the promptImportance
            return mandatoryPrompt.promptImportance || "NEUTRAL"; // Default to NEUTRAL if undefined
        };

    const formatMandatoryContent = (mandatoryPrompt: MandatoryPromptResponse): JSX.Element => {
        const promptKey = mandatoryPrompt.mandatoryPromptId;
    
        // Retrieve promptImportance dynamically
        const promptImportance = getMandatoryPromptImportance(
            mandatoryPrompt.promptGroupId,
            mandatoryPrompt.mandatoryPromptId
        );
    
        const renderAdvice = () => {
            if (!mandatoryPrompt.promptAdvice || !mandatoryPrompt.promptAdvice.trim()) return null;
    
            const adviceClass = promptImportance === 'HIGH' ? 'high-importance' : 'normal-importance';
            return (
                <p>
                    <strong className={adviceClass}>
                        Advice ({promptImportance} {promptImportance === 'HIGH' ? 'Risk' : ''}):
                    </strong>{' '}
                    {mandatoryPrompt.promptAdvice}
                </p>
            );
        };
    
        return (
            <div key={promptKey} className="mb-1 promptAnswer p-4">
                <h6>{mandatoryPrompt.mandatoryPromptName}</h6>
                {renderAdvice()}
            </div>
        );
    };

    const getPromptId = (prompt: PromptResponse | MandatoryPromptResponse): string => {
        return (prompt as PromptResponse).promptId || (prompt as MandatoryPromptResponse).mandatoryPromptId;
    };

    return (
        <div>
            {Array.from(groupedPrompts.keys()).map((groupId) => {
                const prompts = groupedPrompts.get(groupId);
                const promptGroup = getPromptGroupDetails(groupId);

                // Use maxResponsesToShow from the prompt group or default to 3
                const maxResponsesToShow = promptGroup?.maxResponsesToShow ?? 3;
                const showAll = showAllPrompts[groupId] || maxResponsesToShow === 999;

                const shouldShowToggle = prompts && prompts.length > maxResponsesToShow && maxResponsesToShow !== 999;

                return (
                    <div key={groupId} className="pb-3">
                        {promptGroup && (
                            <>
                                <h3>{promptGroup.promptGroupName}</h3>
                                <p>{promptGroup.promptGroupDescription}</p>
                            </>
                        )}

                        {shouldShowToggle && (
                            <p>
                                Found {prompts.length} issues.{' '}
                                <a
                                    className="toggle-link"
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        toggleShowAllPrompts(groupId);
                                    }}
                                >
                                    {showAll ? 'Hide' : 'Show all'}
                                </a>
                            </p>
                        )}

                            {prompts?.slice(0, showAll ? prompts.length : maxResponsesToShow).map((prompt) => (
                                <React.Fragment key={getPromptId(prompt)}>
                                    {'promptId' in prompt
                                        ? formatPromptAnswerContent(prompt as PromptResponse)
                                        : formatMandatoryContent(prompt as MandatoryPromptResponse)}
                                </React.Fragment>
                            ))}

                        <hr className="divider" />
                    </div>
                );
            })}
        </div>
    );
};

export default WorkflowPrompts;