import React , { useState,useEffect,useRef } from 'react';
// Context:
import { usePage } from '../../contexts/PageContext';
//Interfaces and classes:
import {ContractClass} from '../../models/ContractClass';
import{JobClass} from '../../models/JobClass';
import { Message, MessageType } from '../../models/ChatInterfaces';
import {Workflow,WorkflowResponse} from '../../models/ContractInterfaces'
import {ContractMetadata, ProcessedWorkflow} from '../../models/JobInterface'
// Forms and components:
import PageHeader from "../../components/common/PageHeader/PageHeader";
import PageRow from "../../components/common/PageRow/PageRow";
import Card from "../../components/common/Card/Card";
import Chat from '../../components/chat/Chat';
import TextInput from '../../components/form/TextInput/TextInput';
import FormButton from '../../components/form/FormButton/FormButton';
import TextArea from "../../components/form/TextArea/TextArea";
import SelectDropdown from '../../components/form/SelectOption/SelectDropdown';
import JobStatus from '../../components/status/JobStatus';
import JobList from '../../components/list/jobList/JobList';
import WorkflowPrompts from '../../components/workflowPrompts/WorkflowPrompts';
import {useUser } from '../../contexts/UserContext';
import { fetchUsers, UserOption } from '../../api/apiUser';
// API
import {useApiClient } from '../../contexts/ApiContext';
import {ApiContract, ContractListData} from '../../api/apiContract';
import {ApiJob} from '../../api/apiJob';
import {ApiChat} from '../../api/apiChat';
import {ApiWorkflow} from '../../api/apiWorkflow';

const DefaultContent: React.FC = () => {
    // API
    const apiClient = useApiClient();
    const [apiContract] = useState<ApiContract>(new ApiContract(apiClient));
    const [apiChat,setApiChat] = useState<ApiChat | null>(null);
    // Initial chat message:
    const initialMessage: Message = {
        text: "Hello, I'm Ava! You can ask me about the contract and I will do my best to answer you.",
        type: MessageType.Answer,
        created: new Date().toISOString() // Captures the current time as the message creation time
      };
    // Page parameters:
    const {updateParams, getParam} = usePage();
    // Dynamic Data
    const [contractOptions, setContractOptions] = useState([{ value: 'Select Contract', label: 'Select Contract' }]);
    const [workflowOptions,setWorkflowOptions] = useState([{ value: 'Select Workflow', label: 'Select Workflow' }]);
    const [contractDefinitionList, setContractDefinitionList] = useState<ContractListData[]>([]);
    const [jobList, setJobList] = useState<JobClass[]>([]);
    const [messages, setMessages] = useState<Message[]>([initialMessage]);
    const [workflowResponseList, setWorkflowResponseList] = useState<WorkflowResponse[]>([]);
    const [selectedWorkflow,setWorkflow] = useState<Workflow | null>(null);
    const [users, setUsers] = useState<UserOption[]>([]);
    // Url data
    const [urlJobId] = useState<string>(getParam('job')??'');
    const [urlContractId] = useState<string>(getParam('contract')??'');
    const [urlUserId]= useState<string>(getParam('user')??'');
    const [urlWorkflowId]= useState<string>(getParam('workflow')??'');
    // Form
    const [showLoadScreen,setShowLoadScreen] = useState<boolean>(true);
    const [updateWorkflowLoadScreen,setUpdateWorkflowLoadScreen] = useState<boolean>(false);
    const [jobStatus,setJobStatus] = useState('LOADING');
    const [jobProgress,setJobProgress] = useState(5);
    const [workFlowNotificationMessage,setWorkflowNotificationMessage] = useState('');
    // Classes:
    const [job,setJob] = useState<JobClass | null>(null);
    // States:
    const [selectedContractId, setSelectedContractId] = useState('');
    const [selectedContract, setSelectedContract] = useState<ContractClass | null>(null);
    const [selectedWorkflowId, setSelectedWorkflowId] = useState('Select Workflow');
    const [selectedUserId,setSelectedUserId] = useState('Select User');
    const [isWaitingForChatResponse, setIsWaitingForChatResponse] = useState(false);
    // User - set the current user as the default user:
    const { user,getUserId,getDefaultWorkflow } = useUser();
    const currentUserId = getUserId()?? 'unknown';

    // References used to keep states for initial runs to stop re-run when rendering
    const initialLoad = useRef(true);
    const isJobCompleted = useRef(false);
    const initialUsers = useRef(true);
    const initialContract = useRef(true);
    const timerStarted = useRef(false);

    // Fetch users (static data for now, but will be replaced with API call later on):
    useEffect(() => {
        if(initialUsers.current){
            initialUsers.current = false
            const loadUsers = async () => {
            try {
                const userOptions = await fetchUsers(); // Call the simulated API
                setUsers(userOptions); // Set the users directly
            } catch (error) {
                console.error('Error loading user data:', error);
            }
            };
        
            loadUsers();
        }
      }, []);
    // Fetch contracts (once)
    useEffect(() => {
        if(initialContract.current){
            initialContract.current = false;

            // Fetch contracts:
            try{
                const newContractOptions = [
                    { value: 'Select Contract', label: 'Select Contract' },
                ];
                const fetchContracts = async () => {
                    const contractList = await apiContract.fetchContractListFromApi();
                    setContractDefinitionList(contractList);
                    // Create options for the dropdown
                    contractList.forEach((contract: { contractId: string; contractName: string; }) => {
                        newContractOptions.push({ value: contract.contractId, label: contract.contractName });
                    });
                    setContractOptions(newContractOptions);
                };
                fetchContracts();
            } catch (error) {
                console.error('Error loading contracts:', error);
            }
        }
    }, []);
    // Initial load that uses url parameters to set the form and fetch:
    useEffect(() => {
        if (initialLoad.current) {
            initialLoad.current = false;
            let progress = 5; // Reset
            setJobProgress(progress);
            setSelectedContractId(urlContractId);
            setSelectedUserId(urlUserId);
            setSelectedWorkflowId(urlWorkflowId);

            if (urlJobId !== '' && apiClient && showLoadScreen === true) {
                let counter = 0;
                const fetchJobStatus = async () => {
                    // Prevent further fetches if job is completed
                    if (isJobCompleted.current) return;

                    try {
                        const apiJob = new ApiJob(apiClient, urlJobId, urlUserId);
                        const response = await apiJob.getJobStatus(urlJobId, urlUserId);
                        
                        if (response.data[0].status === 'COMPLETED') {
                            isJobCompleted.current = true; // Mark job as completed
                            clearInterval(intervalId); // Stop fetching when the job is completed

                            const newJob = new JobClass(response.data[0]);
                            setJob(newJob);

                            const contractList: ContractListData[] = await apiContract.fetchContractListFromApi() as ContractListData[];
                            const latestContractVersion = contractList.find((contract) => contract.contractId === newJob.contractMetadata?.contractId)?.createdAt;
                            const newContract = await fetchContract(newJob.contractMetadata?.contractId ?? '', latestContractVersion ?? '');
                            
                            setSelectedUserId(newJob.userId ?? '');
                            await fetchJobs(newJob.userId ?? '', newJob.contractMetadata?.contractId ?? '');
                            setSelectedWorkflowId(urlWorkflowId ?? '');
                            setWorkflow(newContract?.contractDetails.workflows.find(workflow => workflow.workflowId === urlWorkflowId) ?? null);
                            fetchWorkflowResults(urlWorkflowId, newJob, newContract?.createdAt);
                            
                            setShowLoadScreen(false);
                            const chat = new ApiChat(apiClient, urlJobId, currentUserId);
                            setApiChat(chat);
                            updateParams({}, ['loading']);
                            setJobProgress(100);
                        } else {
                            // Handle other statuses and progress updates
                            setJobStatus(response.data[0].status);
                            if (progress < 95) {
                                setJobProgress(prevProgress => prevProgress + 5);
                            } else if (progress < 130) {
                                setJobProgress(prevProgress => prevProgress + 1);
                            }
                            progress += 5;
                        }

                        counter++;
                        if (counter === 35) {
                            alert("Seems something went wrong, please contact tech for help.");
                            clearInterval(intervalId);
                            setShowLoadScreen(false);
                            updateParams({}, ['jobid', 'contract', 'workflow', 'user']);
                            setSelectedWorkflowId('Select Workflow');
                            setSelectedContractId('Select Contract');
                        }
                    } catch (error) {
                        console.error('Error fetching status:', error);
                    }
                };

                fetchJobStatus(); // Immediately fetch the job status when the component mounts

                // Set the interval to fetch the job status every 3 seconds
                const intervalId = setInterval(fetchJobStatus, 3000);

                // Clear the interval on component unmount or when the conditions change
                return () => {
                    clearInterval(intervalId);
                };
            } else {
                let user = urlUserId !== '' ? urlUserId : currentUserId;
                setSelectedUserId(user);
                setShowLoadScreen(false);
            }
        }
    }, []);
    // show loadscreen and update progress
    useEffect(() => {
        if(updateWorkflowLoadScreen){
            if(!timerStarted.current){
                timerStarted.current = true;
                setJobProgress(5);
                asyncTimer(35);
            }
        }
    }, [updateWorkflowLoadScreen]);



    // Handlers:
    const handleSelectContract = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        // Clear everything except user:
        setWorkflowResponseList([]);
        setJob(null);
        setJobList([]);
        setWorkflowNotificationMessage('');

        // Fetch contract data:
        const contractTypeId = e.target.value;
        // Load data: 
        if (contractTypeId !== 'Select Contract' && apiContract) {
            // find version from contractDefinitionList:
            const contractVersion = contractDefinitionList.find((contract) => contract.contractId === contractTypeId)?.createdAt;
            // Check that contract version is found
            if(contractVersion === undefined){
                console.error('Error when trying to find contract version.');
                return;
            }
            setSelectedContractId(contractTypeId);
            await fetchContract(contractTypeId, contractVersion);
            fetchJobs(selectedUserId,contractTypeId);
        } else {
            setSelectedContract(null); // Reset when "Select Contract" is chosen
            updateParams({},['job','workflow','contract']);
        }
    };
    const handleSelectUser = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const userId = e.target.value;
        setSelectedUserId(userId);
        // Fetch available contracts
        if (userId !== 'Select User') {
            // Call the inner async function immediately
            if(selectedContractId !== 'Select Contract'){
                // Fetch jobs for the user and contract:
                fetchJobs(userId,selectedContractId);
                updateParams({'user':userId},['job']);
            }
        }else{
            setJobList([]);
        }
    };
    const handleSelectJob = (jobId: string) => {
        setWorkflowNotificationMessage('');
        setWorkflowResponseList([]);
        // Create chat:
        const chat = new ApiChat(apiClient, jobId, currentUserId);
        setApiChat(chat);
        setMessages([initialMessage]);
        // find the job from jobList that matches the jobId and set job to selected job:
        const selectedJob = jobList.find((job) => job.jobId === jobId);
        if (selectedJob) {
            setJob(selectedJob);
            const defaultWorkflowId = getDefaultWorkflow();
            const jobWorkflows = selectedJob.processedWorkflows??[];
            
            // Check if selected workflow exist in the contract:
            if(selectedWorkflowId != 'Select Workflow' && selectedWorkflowId != null){
                const workflowExists = jobWorkflows.some(workflow => workflow.workflowId === defaultWorkflowId);
                if(workflowExists){
                    updateParams({'job':jobId},[]);
                    setSelectedWorkflowId(selectedWorkflowId);
                    fetchWorkflowResults(selectedWorkflowId,selectedJob,selectedContract?.createdAt);
                }
            }else{
                // Check if defaultWorkflow is in the list of workflows for the contract:
                const workflowExists = jobWorkflows.some(workflow => workflow.workflowId === defaultWorkflowId);
                if(workflowExists){
                    setSelectedWorkflowId(defaultWorkflowId);
                    updateParams({'workflow':defaultWorkflowId,'job':jobId},[]);
                    fetchWorkflowResults(defaultWorkflowId,selectedJob,selectedContract?.createdAt);
                }
                else{
                    setSelectedWorkflowId('Select Workflow');
                    updateParams({'job':jobId},[]);
                }
            }
        } else {
            console.error(`Job ${jobId} not found in jobList`);
        }
        
    }
    const handleSelectWorkflow = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedWorkflowId(e.target.value);
        if(e.target.value !== 'Select Workflow'){
            // Set workflow and check version:
            setWorkflow(selectedContract?.contractDetails.workflows.find(workflow => workflow.workflowId === e.target.value)??null);
            fetchWorkflowResults(e.target.value,job,selectedContract?.createdAt);
            updateParams({'workflow': e.target.value},[]);
        }else{
            setWorkflowResponseList([]);
        }
    }
    const handleChatQuestion = (newMessageText: string) => {
        const newMessage = {
            text: newMessageText,
            type: MessageType.Question,
            created: new Date().toISOString()
        };
    
        // Update messages with the new question
        setMessages(prevMessages => [...prevMessages, newMessage]);
    
        // Set waiting state to true before API request
        setIsWaitingForChatResponse(true);

        if (apiChat) {
            apiChat.askQuestion(newMessageText)
                .then((responseMessage) => {
                    setMessages(prevMessages => [...prevMessages, responseMessage]);
                })
                .catch(error => {
                    console.error('Error fetching chat:', error);
                })
                .finally(() => {
                    // Set waiting state to false after the request is completed or fails
                    setIsWaitingForChatResponse(false);
                });
        } else {
            // Immediately set waiting state to false if apiChat is not available
            setIsWaitingForChatResponse(false);
        }
    };
    const handleAnalyzeOrUpdateWorkflow = async () => {
        // Ensure job, jobId, and selectedContract are available
        if (!job?.jobId || !selectedContract) {
            console.error('Error when trying to analyze or update workflow.');
            cleanupWorkflowLoadScreen();
            return;
        }
    
        try {
            setUpdateWorkflowLoadScreen(true);
            const apiWorkflow = new ApiWorkflow(apiClient);
            const response = await apiWorkflow.generateWorkflowResponses(job.jobId, selectedWorkflowId, job.userId, job, selectedContract);
            
            // Update workflow and prompt list
            const workflow = selectedContract.contractDetails.workflows.find(workflow => workflow.workflowId === selectedWorkflowId) ?? null;
            setWorkflow(workflow);
            setWorkflowResponseList(response);
    
            // Fetch updated job list
            await fetchJobs(selectedUserId, selectedContractId);
    
            // Clear workflow notification message
            setWorkflowNotificationMessage('');
        } catch (error) {
            console.error('Error fetching workflow:', error);
        } finally {
            // Cleanup after workflow update
            cleanupWorkflowLoadScreen();
        }
    };
    
    
    
    // Helper functions:
    async function fetchContract(contractId: string, contractVersion: string): Promise<ContractClass | null> {
        try {
            if (apiContract) {
                const contractData = await apiContract.fetchContractMetaFromApi(contractId, contractVersion);
    
                // Ensure contractData is an instance of ContractClass
                let contract: ContractClass;
                if (contractData instanceof ContractClass) {
                    contract = contractData;
                } else {
                    contract = new ContractClass(contractData);
                }
    
                // Get name, stored in contract list:
                contract.contractName = contractDefinitionList.find((c) => c.contractId === contractId)?.contractName ?? "NA";
                setSelectedContract(contract);
    
                const newWorkflowOptions = [{ value: 'Select Workflow', label: 'Select Workflow' }];
                if (contract.contractDetails.workflows.length > 0) {
                    contract.contractDetails.workflows.forEach((workflow) => {
                        newWorkflowOptions.push({ value: workflow.workflowId, label: workflow.workflowName });
                    });
                }
                setWorkflowOptions(newWorkflowOptions);
                // If selected workflow exists in the contract, then leave it. If selected workflow is Select Workflow, then see if user's default workflow is in the contract and select it.
                if (selectedWorkflowId === 'Select Workflow' || selectedWorkflowId === null|| selectedWorkflowId === '') {
                    const defaultWorkflowId = getDefaultWorkflow();
                    // Check if default workflow exists in the contract. If it does, set it as selected. If it does not, set it to Select Workflow.
                    const workflowExists = contract.contractDetails.workflows.some(workflow => workflow.workflowId === defaultWorkflowId);
                    if (workflowExists) {
                        setSelectedWorkflowId(defaultWorkflowId);
                        setWorkflow(contract.contractDetails.workflows.find(workflow => workflow.workflowId === defaultWorkflowId)??null);
                        updateParams({ 'workflow': defaultWorkflowId, 'contract': contractId, 'user': selectedUserId }, []);
                    }
                } else {
                    // Check if selected workflow exists in the contract. If it does, do nothing. If it does not, set it to Select Workflow.
                    const workflowExists = contract.contractDetails.workflows.some(workflow => workflow.workflowId === selectedWorkflowId);
                    if (!workflowExists) {
                        setSelectedWorkflowId('Select Workflow');
                        updateParams({ 'contract': contractId, 'user': selectedUserId }, ["workflow"]);
                    }
                }
    
                return contract;
            } else {
                console.error("API contract is not available.");
                return null; // Return null if apiContract is not available
            }
        } catch (error) {
            console.error('Failed to initialize the contract:', error);
            setSelectedContract(null); // Reset or handle errors appropriately
            return null; // Return null in case of error
        }
    }
    async function fetchJobs(userId: string,contractId:string){
        try {
            // Fetch jobs available for the user:
            const apiJob = new ApiJob(apiClient);
            const newJobList = await apiJob.fetchAllRecentJobs(userId,contractId);
            // Filter out jobs based on contract type:
            setJobList(newJobList);
        } catch (error) {
            console.error('Error fetching jobs:', error);
        }
    }
    function fetchWorkflowResults(workflowId:string,activeJob:JobClass|null,contractCreatedAt:string|null|undefined){
        if(activeJob && contractCreatedAt){
            try{
                
                // Load workflow data:
                const apiWorkflow = new ApiWorkflow(apiClient);
                //Create a list of all workflows with same id in the processedWorkflows list of the job object with right ID:
                const processedWorkflows = getProcessedWorkflowsById(activeJob.processedWorkflows??[],workflowId)
                // The list may contain more than one workflow with the same id, but different timestamp. Sort the list by timestamp:
                processedWorkflows.sort((a, b) => (a.processedTimestamp > b.processedTimestamp) ? 1 : -1);
                // Get the last element in the list:
                const lastProcessedWorkflow = processedWorkflows[processedWorkflows.length - 1];
                // Now you can check version:
                checkWorkflowVersion(lastProcessedWorkflow.workflowContractMetadata,contractCreatedAt);

                apiWorkflow.fetchWorkflowResults(lastProcessedWorkflow.sessionId)
                    .then((response) => {
                        try{
                            setWorkflowResponseList(response);
                        }catch(e){
                            throw new Error('Error when trying to fetch workflow results.');
                        }
                    })
                    .catch(error => {
                        console.error('Error fetching workflow:', error);
                    });
                }catch(e){
                    throw new Error('Error when trying to fetch workflow results.');
                }
        }
    }
    function checkWorkflowVersion(workflowContractMeta:ContractMetadata,contractProdVersion:string){
        // Fetch version from job and contract
        const jobVersion = workflowContractMeta.createdAt;
        if(jobVersion !== contractProdVersion){
            setWorkflowNotificationMessage("The list of questions that the AI sends to the contract has changed since the job was created. You should re-run the AI analysis.");
        }
    }
    function getProcessedWorkflowsById(processedWorkflows: ProcessedWorkflow[], id: string): ProcessedWorkflow[] {
        return processedWorkflows.filter(workflow => workflow.workflowId === id);
    }
    const cleanupWorkflowLoadScreen = () => {
        setUpdateWorkflowLoadScreen(false);
        timerStarted.current = false;
    };
    async function asyncTimer(seconds: number) {
        let counter = 0;
    
        while (counter < seconds && timerStarted.current) {
            await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second
    
            if (counter < seconds) {
                // Calculate progress and update
                setJobProgress(prevProgress => {
                    const newProgress = Math.floor(Math.min(100, prevProgress + 100 / seconds));
                    return newProgress;
                });
            }
    
            // Check if system is ready and stop the process if so
            if (!timerStarted.current) {
                break; // Exit loop if the condition is met
            }
            counter++; // Increment counter
        }
    
        // Additional loop in case updateWorkflowLoadScreen remains true
        counter = 0
        while (timerStarted.current && counter <30) {
            await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second
            counter++;
        }

        // Give error message, this should not happen:
        if(timerStarted.current){
            alert("Error - process did not end properly")
        }

        // Reset timer:
        timerStarted.current = false;
    }

    return (
        <>
        <PageHeader title="List of Contracts" />
        <PageRow className={`px-4 ${showLoadScreen !== true && updateWorkflowLoadScreen !== true ? 'visually-hidden' : ''}`}>
            <Card 
                columnSize="col-md-12"
                title="Status Ongoing NaviLex Workflow Processing"
                hideCardBody={false}
            >
                <JobStatus status={jobStatus} progress={jobProgress} />
            </Card>
        </PageRow>
        <PageRow className={`px-4 ${showLoadScreen === true || updateWorkflowLoadScreen === true ? 'visually-hidden' : ''}`}>
            <div className="col-md-4">
                <Card 
                    columnSize="col-md-12"
                    title="Select Job"
                    hideCardBody={false}
                >
                    <PageRow>
                        <SelectDropdown
                            id="contract-select"
                            label="Contract Type:"
                            options={contractOptions}
                            value={selectedContractId}
                            onChange={handleSelectContract}
                            columnClass="col-md-5 px-2 pl-md-2 pe-md-0"
                        />
                        <SelectDropdown
                            id="contract-select"
                            label="Select User:"
                            options={users}
                            value={selectedUserId}
                            onChange={handleSelectUser}
                            columnClass="col-md-7 px-2"
                            disabled={selectedContractId === 'Select Contract'}
                        />
                    </PageRow>
                    <PageRow>
                            <SelectDropdown 
                                id="workflow-type"
                                label="Select Workflow:"
                                options={workflowOptions}
                                value={selectedWorkflowId??"Select Workflow"}
                                onChange={(e) => handleSelectWorkflow(e)}
                                columnClass="col-md-6 px-2 pl-md-2 pe-md-0"
                                disabled={selectedContractId === 'Select Contract'}
                            />
                            <FormButton
                                label="Start AI Analysis"
                                onClick={() => handleAnalyzeOrUpdateWorkflow()}
                                columnClass="col-md-6 px-2 "
                                // both contractName and selectedContractTye must be filled
                                disabled={selectedWorkflowId === "Select Workflow" || workFlowNotificationMessage === ''}
                            />
                        </PageRow>
                        <PageRow className={workFlowNotificationMessage === '' ? 'visually-hidden' : ''}>
                            <div className="col-md-12 px-2 mb-2">
                                <div className="alert alert-danger" role="alert">
                                    <b>Notice:</b><br/>{workFlowNotificationMessage}
                                </div>
                            </div>
                        </PageRow>
                    <div className={(selectedUserId === 'Select User') ? 'visually-hidden' : undefined}>
                        <PageRow className={jobList?.length < 1 ? "visually-hidden" : ""}>
                            <label className="col-md-12 px-2 form-label fw-semibold mt-2">Historical Jobs:</label>
                        </PageRow>
                        <PageRow>
                            <JobList
                                jobs={jobList}
                                onSelectJob={(jobId: string) => handleSelectJob(jobId)}
                                selectedJob={job?.jobId}
                                maxHeight="380px"
                            />
                        </PageRow>
                    </div>
                </Card>
                <Card 
                    columnSize="col-md-12"
                    title="Contract Details"
                >
                    <div className={(!job || selectedUserId === 'Select User') ? 'visually-hidden' : undefined}>
                        <PageRow>
                            <TextInput
                                id="contract-name"
                                label="Job Name:"
                                name="contractName"
                                value={job?.jobMetadata?.jobName??'Job Name'}
                                onChange={() => {}}
                                placeholder="Contract Name"
                                columnClass='col-md-8 px-2'
                                disabled={true}
                            />
                            <TextInput
                                id="job-date"
                                label="Created:"
                                name="jobDate"
                                // Only use the yyyy-mm-dd from timestamp
                                value={job?.createdOrUpdatedTimestamp?.split('T')[0]??'Date'}
                                onChange={() => {}}
                                placeholder="Date"
                                columnClass='col-md-4 px-2'
                                disabled={true}
                            />
                        </PageRow>
                        <PageRow>
                            <TextArea
                                id="job-comments"
                                label="Comments from initiator:"
                                name="jobComments"
                                value={job?.jobMetadata?.jobComments??'Job Comments'}
                                onChange={() => {}}
                                placeholder="Comments"
                                rows={3}
                                scrollable={true}
                                columnClass='col-md-12 px-2'
                                disabled={true}
                            />
                        </PageRow>
                        
                    </div>
                </Card>
                <Card 
                    columnSize="col-md-12"
                    title="Chat"
                >
                    <div className={(!job || selectedUserId === 'Select User') ? 'visually-hidden' : undefined}>
                        <Chat messages={messages} onMessageSubmit={handleChatQuestion} maxHeight='450px' isLoading={isWaitingForChatResponse}/>
                    </div>
                </Card>
            </div>
            <Card 
                columnSize="col-md-8"
                title="Findings & Advice"
                >
                    <WorkflowPrompts workflowResponses={workflowResponseList} workflow={selectedWorkflow} />
            </Card>
        </PageRow>
        </>
    );
}

export default DefaultContent;