// apiUser.ts

export interface UserOption {
    value: string;
    label: string;
  }
  
  export const fetchUsers = async (): Promise<UserOption[]> => {
    // Simulate an asynchronous API call with hardcoded user data
    return new Promise((resolve) => {
      setTimeout(() => {
        const users = [
          "amine.el-menzhi@westernbulk.com",
          "elisabeth.hammero@westernbulk.com",
          "manuela.rieger@westernbulk.com",
          "ludvig.leivestad@westernbulk.com",
          "jeppe.haug@westernbulk.com",
          "markus.stokkebo@westernbulk.com",
          "oyvind.hellem@westernbulk.com",
          "bailo.diallo@westernbulk.com",
          "lone.johansen@westernbulk.com",
          "martin.stenberg@westernbulk.com",
          "suti.holst@westernbulk.com",
          "maria.dellgren@westernbulk.com",
          "Christopher.Holtskog@westernbulk.com",
          "amit.rahul@westernbulk.com",
          "charlie.russell@westernbulk.com",
          "patrick.feng@westernbulk.com",
          "Francesca.Scarrozza@westernbulk.com",
          "haziq.danish@westernbulk.com",
          "grey.ayad@westernbulk.com",
          "rayna.tan@westernbulk.com",
          "isabella.kwok@westernbulk.com",
          "tink.chiang@westernbulk.com",
          "lars.tollefsen@westernbulk.com",
          "lisbeth.nordbo@westernbulk.com",
          "yin.shi.lee@westernbulk.com",
          "wei-ching.yeow@westernbulk.com",
          "kristoffer.skagen@westernbulk.com",
          "marianne.rogeberg@westernbulk.com",
          "vibeke.forsman@westernbulk.com",
          "jon.magnus.kvamer@westernbulk.com",
          "gozde.kasap@westernbulk.com",
          "kim.aaberg@westernbulk.com",
          "joachim.frantsen@westernbulk.com",
          "arild.bunes@westernbulk.com",
          "umut.tasal@westernbulk.com",
          "thomas.bjastad@westernbulk.com",
          "anders.dyrdal@westernbulk.com",
          "finn.mohn@westernbulk.com",
          "olav.haldorsen@westernbulk.com",
          "jorgen.veie-rosvoll@westernbulk.com",
          "havard.haaland@westernbulk.com",
          "pranjal.gupta@westernbulk.com",
          "joachim.lee@westernbulk.com",
          "magnus.krogsmyr@westernbulk.com",
          "torbjorn.gjervik@westernbulk.com",
          "arturs.krumins@westernbulk.com",
          "petter.haraldson@westernbulk.com",
          "jorge.dittrich@westernbulk.com",
          "diego.jacob@westernbulk.com",
          "rasmus.olsen@westernbulk.com",
          "mohneesh.bhutani@westernbulk.com",
          "amit.jakhmola@westernbulk.com",
          "maria.athanassiadi@westernbulk.com",
          "laurent.grasset@westernbulk.com",
          "yvonne.gu@westernbulk.com",
          "moire.hammer@westernbulk.com",
          "magnus.hansen@westernbulk.com",
          "oystein.boe@westernbulk.com",
          "yutaro.yamamoto@westernbulk.com",
          "henrik.klokk@westernbulk.com",
          "jake.millington@westernbulk.com",
          "wei-min.chia@westernbulk.com",
          "henrik.synnes@westernbulk.com",
          "allan.jiang@westernbulk.com",
          "casper.tonnesen@westernbulk.com",
          "hanne.wang.paulsen@westernbulk.com",
          "bente.lyngroth@westernbulk.com",
          "marina.sharafutdinova@westernbulk.com",
          "verena.boysen@westernbulk.com",
          "catherine.lee@westernbulk.com",
          "kai.ling.fong@westernbulk.com",
          "ting.en.lim@westernbulk.com",
          "yenny.sie@westernbulk.com",
          "kenneth.thu@westernbulk.com",
          "orjan.svanevik@westernbulk.com",
          "hans.aasnas@westernbulk.com",
          "egil.husby@westernbulk.com",
          "jonas.larsen@westernbulk.com",
          "nigel.leong@westernbulk.com",
          "gustav.arvidsson@westernbulk.com",
          "henrik.ramm@westernbulk.com",
          "vidya.thakur@westernbulk.com",
          "jessica.moylan@westernbulk.com",
          "anett.sundelius@westernbulk.com",
          "frida.escobar@westernbulk.com",
          "may.yong@westernbulk.com",
          "karolina.orleanski@westernbulk.com",
          "alan.curran@westernbulk.com",
          "karolos.mavromichalis@westernbulk.com",
          "david.ng@westernbulk.com",
          "fazlina.sapuan@westernbulk.com",
          "trang.nguyen@westernbulk.com",
          "danielle.pereira@westernbulk.com",
          "svein-erik.johnsrud@westernbulk.com",
          "hedda.evju@westernbulk.com",
          "lene.rossland@westernbulk.com",
          "gila.belau@westernbulk.com",
          "cathrine.fosse@westernbulk.com",
          "katrine.knobel@westernbulk.com",
          "anstein.eie@westernbulk.com",
          "sina.ehsani@westernbulk.com",
          "marianne.loe@westernbulk.com",
          "martin.hjelle@westernbulk.com",
          "sverre.strom@westernbulk.com",
          "torkjel.hurtig@westernbulk.com",
          "edward.grandstaff@westernbulk.com",
          "patrick.ness@westernbulk.com",
          "ranjith.venkatarajanahalli@westernbulk.com",
          "anusha.dk@westernbulk.com",
          "xiaoxiao.tao@westernbulk.com",
          "eirik.berg@westernbulk.com"
        ];
  
        // Transform the data to the required format
        const userOptions = users.map(email => {
            const name = email.split('@')[0].toLowerCase(); // Ensure lowercase
            return { value: name, label: name };
          });
  
        // Sort the user options alphabetically
        userOptions.sort((a, b) => a.label.localeCompare(b.label));
  
        resolve(userOptions);
      }, 100); // Simulated delay
    });
  };